import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import { ArrowCircleLeftIcon } from '@heroicons/react/solid'

import Layout from "../components/layout"
import { NAVBAR_PADDING } from "../components/navbar"

// Provide common components here
const components = {
  a: Link
}

export default function BlogPost({ data }) {
  const post = data.mdx
  return (
    <Layout>
      <div className={`${NAVBAR_PADDING}`}>
        <div className="container md:max-w-3xl py-10">
          <div className="px-4 md:px-6 prose prose-slate md:prose-lg">
            <Link className="no-underline transition text-slate-300 hover:text-slate-400" to="/">
              <ArrowCircleLeftIcon className="h-5 w-5 inline-block"/>
              <span>Home</span>
            </Link>
            <h1 className="pt-4">{post.frontmatter.title}</h1>
            <MDXProvider components={components}>
                <MDXRenderer frontmatter={post.frontmatter}>{post.body}</MDXRenderer>
            </MDXProvider>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query BlogPostByID($slug: String!) {
    mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        title
      }
    }
  }
`